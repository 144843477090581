export default {
  h1: {
    color: "#4655A5",
    fontWeight: 700,
    fontSize: 35,
    letterSpacing: "-0.24px",
  },
  h2: {
    color: "#4655A5",
    fontWeight: 400,
    fontSize: 28,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
  },
  h5: {
    color: "#4655A5",
    fontStyle: "bold",
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  overline: {
    fontWeight: 500,
  },
};
