import { Document, Page } from "react-pdf";

import React from "react";
import Moment from "moment";
import "src/CalendarPicker.css";

export default class PDFViewer extends React.Component {
  render() {
    return <div></div>;
  }
}
